import React from "react";
import Layout from "../components/Layout";
import PremiumContent from "../components/PremiumContent";

export default () => {
  return (
    <Layout pageTitle="The Comparison Company - Premium Service" pageDescription="Sign up for even more providers, products and choice with The Comparison Company Premium.">
      <PremiumContent />
    </Layout>
  );
};
